import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  innerWidth,
  mainPurple,
  mainBlack,
  subFont,
  screen,
} from "../components/variables";
import {
  IconCircleDarkPurpleMd,
  IconCircleLightPurpleMd,
  IconCircleCyanMd,
} from "../components/circles";
import { FaFacebook, FaLinkedin, FaInstagram } from "react-icons/fa";
import Obfuscate from "react-obfuscate";

const Wrapper = styled.div`
  min-height: calc(100vh - 284px);
  overflow: hidden;

  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 32px;
    @media ${screen.xxsmall} {
      padding: 0 42px;
    }
    @media ${screen.medium} {
      padding: 0;
    }
  }

  .header {
    .heading {
      font-weight: 900;
      line-height: 1.22;
      position: relative;
      font-size: 2rem;
      @media ${screen.xxsmall} {
        font-size: 3.5rem;
        display: block;
      }
      @media ${screen.small} {
        font-size: 5rem;
        line-height: 1.09;
      }
    }
  }

  .flex-row {
    display: flex;
    flex-direction: column;
    margin: 32px 0 0 0;
    @media ${screen.xsmall} {
      flex-direction: row;
      margin: 42px 0 110px 0;
    }

    .col {
      &--map {
        width: 100%;
        position: realtive;
        order: 2;
        margin: -20px 0 0 0;
        @media ${screen.xxsmall} {
          margin: 0 0 100px 0;
        }
        @media ${screen.xsmall} {
          order: 0;
          width: 50%;
          margin: 0;
        }
        @media ${screen.medium} {
          width: 62%;
        }

        .iframe-wrapper {
          position: relative;
          width: 100%;
          padding: 56.25% 0 0 0;

          iframe {
            position: absolute !important;
            top: 0 !important;
            left: 0 !important;
            bottom: 0 !important;
            right: 0 !important;
            width: 100% !important;
            height: 100% !important;
            border: 0 !important;
          }
        }

        .icon-circle {
          &--cyan {
            width: 120px;
            top: -570px;
            right: -100px;
            @media ${screen.xxsmall} {
              display: none;
            }
            @media ${screen.xsmall} {
              display: block;
              width: 175px;
              bottom: 45px;
              left: -145px;
              right: auto;
            }
          }
          &--dark-purple {
            display: none;
            @media ${screen.xxsmall} {
              display: block;
              width: 60px;
              bottom: -30px;
              left: -30px;
            }
          }
        }
      }

      &--details {
        position: relative;
        @media ${screen.xsmall} {
          padding: 0 0 0 50px;
          width: 50%;
        }
        @media ${screen.medium} {
          width: 38%;
          padding: 0 0 0 70px;
        }

        .row {
          display: flex;
          flex-direction: column;
          margin: 0 0 42px 0;
          @media ${screen.xxsmall} {
            flex-direction: row;
          }

          .col {
            .link {
              color: ${mainBlack};
              display: inline-block;
              font-size: 1.13rem;
              line-height: 1.7;
              margin: 0 0 7px 0;
              @media ${screen.xxsmall} {
                font-size: 1.16rem;
                margin: 0;
              }
              @media ${screen.withCursor} {
                &:hover {
                  color: ${mainPurple};
                }
              }

              &--address {
                max-width: 330px;
              }
            }

            .social-list {
              display: flex;
              margin: 11px 0 0 0;

              li {
                a {
                  color: ${mainBlack};
                  display: block;
                  font-size: 1.95rem;
                  margin: 0 28px 0 0;
                  @media ${screen.xxsmall} {
                    font-size: 1.7rem;
                    margin: 0 25px 0 0;
                  }

                  @media ${screen.withCursor} {
                    &:hover {
                      color: ${mainPurple};
                    }
                  }
                }
              }
            }

            &--subheading {
              @media ${screen.xxsmall} {
                width: 20%;
              }
              @media ${screen.medium} {
                width: 27%;
              }

              h6 {
                font-size: 1.1rem;
                font-family: ${subFont};
                margin: 0 0 18px 0;
                @media ${screen.xxsmall} {
                  font-size: 1.42rem;
                  margin: 0;
                }
              }
            }

            &--body {
              width: 80%;
              @media ${screen.medium} {
                width: 73%;
              }
            }

            .hours {
              margin: 16px 0 0 0;

              .flex-hour {
                display: flex;
                margin: 0 0 12px 0;

                span {
                  width: 50%;
                  display: block;
                  font-size: 1rem;
                  font-weight: 300;
                  @media ${screen.xxsmall} {
                    font-size: 1.03rem;
                  }
                }
              }
            }
          }
        }

        .icon-circle {
          display: none;
          @media ${screen.xxsmall} {
            display: block;
            width: 112px;
            top: -85px;
            right: -90px;
          }
          @media ${screen.small} {
            right: -150px;
            top: -104px;
          }
        }
      }
    }
  }
`;

// google map style to be passed as prop

const ContactPage = ({ data }) => {
  const {
    heading,
    phone_number,
    email_address,
    open_hours,
    title_tag,
    meta_description,
  } = data.content.data;

  const pageURL = `https://www.k9swim.com.au/contact/`;

  return (
    <Layout hideFooter>
      <SEO
        title={title_tag || "Contact"}
        description={meta_description}
        url={pageURL}
      />
      <Wrapper>
        <div className="inner-wrapper">
          <header className="header">
            <h1 className="heading">{heading.text}</h1>
          </header>

          <section className="flex-row" data-sal="fade" data-sal-duration="700">
            <div className="col col--map">
              <div className="iframe-wrapper">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3325.0280365186954!2d150.75195371520198!3d-33.552647880744765!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b0d7cc677e34b67%3A0xef80840f3f526c58!2s853%20Kurmond%20Rd%2C%20North%20Richmond%20NSW%202754!5e0!3m2!1sen!2sau!4v1630367549002!5m2!1sen!2sau"
                  title="K9 Map"
                  allowFullscreen=""
                  loading="lazy"
                ></iframe>

                <IconCircleCyanMd className="icon-circle icon-circle--cyan" />
                <IconCircleDarkPurpleMd className="icon-circle icon-circle--dark-purple" />
              </div>
            </div>

            <div className="col col--details">
              <div className="row">
                <div className="col col--subheading">
                  <h6>SAY HI</h6>
                </div>
                <div className="col col--body">
                  <a
                    className="link"
                    href={`tel:${phone_number}`}
                    aria-label="Phone number"
                  >
                    {phone_number}
                  </a>
                  <br />
                  <Obfuscate className="link" email={email_address} />

                  <ul className="social-list">
                    <li>
                      <a
                        href="https://www.facebook.com/CanineHydrotherapyCentre"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaFacebook />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/k9-swim"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaLinkedin />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/k9_swim"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaInstagram />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col col--subheading">
                  <h6>FIND US</h6>
                </div>
                <div className="col col--body">
                  <a
                    className="link link--address"
                    href="https://www.google.com/maps/place/853+Kurmond+Rd,+North+Richmond+NSW+2754/@-33.5526568,150.7519609,17z/data=!3m1!4b1!4m5!3m4!1s0x6b0d7cc677fdb14b:0x582327bf9ab9975a!8m2!3d-33.5526613!4d150.7541496"
                    target="_blank"
                    rel="noreferrer"
                  >
                    853 Kurmond Road, North Richmond NSW Australia 2754
                  </a>
                  <div className="hours">
                    {open_hours.map((item) => (
                      <div key={item.day} className="flex-hour">
                        <span>{item.day}</span>
                        <span>{item.time}</span>
                      </div>
                    ))}

                    <div></div>
                  </div>
                </div>
              </div>

              <IconCircleLightPurpleMd className="icon-circle" />
            </div>
          </section>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default ContactPage;

export const dataQuery = graphql`
  {
    content: prismicContactPage {
      data {
        heading {
          text
        }
        phone_number
        email_address
        open_hours {
          day
          time
        }
        title_tag
        meta_description
      }
    }
  }
`;
